import * as React from 'react';
import Layout from "../../../components/layout";
import Banner from "../../../components/project-details/banner";
import Result from "../../../components/project-details/result";
import KeyFeatureAllIn from "../../../components/project-details/key-feature-all-in";
import AppDetailTechnologySlider from "../../../components/project-details/app-detail-technology-slider";
import {ASSETS} from "../../../components/common/images";
import KeyFeature from "../../../components/project-details/key-feature";
import PageNotFound from "../../404";
const Detail = (props) => {
    const { name } = props;

    const data = [
        {
            "name": "all-in",
            "data": {
                projectTitle: `A Journey to Comfortable <span>Language Learning!</span>`,
                projectImage: ASSETS.APPDETAILS.AllInProject,
                projectDescription: `Learn popular Modern Foreign Languages (MFL) and English as Additional Language (EAL).
        All-in offers access to resources and classroom tools that make it easier for professionals to deliver outstanding language lessons.
        It is a zero-effort package, full of fun, and is additive.`,
                technology: [
                    ASSETS.APPDETAILS.Aws,
                    ASSETS.APPDETAILS.PhpImg,
                    ASSETS.APPDETAILS.ElasticSearch,
                    ASSETS.APPDETAILS.SymfonyImg,
                ],
                resultDescription: "It is said that a good process produces good results! All-in wanted to create a website with resources and activities, helping people to learn MFL and EAL. Hence, team GeekyBones worked together, creating a feature-rich website, witnessing bright results. Wonders lie in learning foreign languages. So, don't limit yourself!",
                resultSlideList: [
                ASSETS.APPDETAILS.AllIn1,
                ASSETS.APPDETAILS.AllIn2,
                ASSETS.APPDETAILS.AllIn3,
                ASSETS.APPDETAILS.AllIn4,
                ASSETS.APPDETAILS.AllIn5,
                ],
                listText1: 'Select topic',
                listText2: 'Select class code (optional)',
                listText3: 'Selected subtopics/sections',
                listText4: 'Choose Languages',
                listText5: 'Select Date, Lesson Title & Learning Objective (optional)',
                listText6: 'Select topic',
                listText7: 'Select class code (optional)',
                listText8: 'Selected subtopics/sections',
                listText9: 'Choose Languages',
                metaTitle: 'All-In Project - Web Development Case Studies | GeekyBones',
                metaDescription: 'Get started on a journey to comfortable learning. Learn more about the All-in website development and challenges faced by the GeekyBones. ',
            },
        },
        {
            "name": "sports-gear-swag",
            "data": {
                projectTitle: `Custom Sports Apparels to Capture the Identity of your Brand`,
                projectImage: ASSETS.APPDETAILS.SportGearProject,
                projectDescription: `Sports Gear Swag (SGS) believes that people deserve high-quality sports gear and an effortless style. SGS crafts elite jerseys and team apparel for everyday life. Comfort is a priority that incorporates impeccable craftsmanship, and highest quality materials.
`,
                technology: [
                    ASSETS.APPDETAILS.FabricJs,
                    ASSETS.APPDETAILS.PhpImg,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.MySql,
                    ASSETS.APPDETAILS.SymfonyImg,
                ],
                resultDescription: "The main aim of Sports Gear Swag was to offer seamless customer experience where they can get their customized apparels. Team GeekyBones incorporated the right technologies and ideas, helping the client to reach business goals through a successful shopping website.",
                resultSlideList: [
                    ASSETS.APPDETAILS.SportHome,
                    ASSETS.APPDETAILS.SportBasketball,
                    ASSETS.APPDETAILS.SportFootball,
                    ASSETS.APPDETAILS.SportVolleyball,
                    ASSETS.APPDETAILS.SportBaseball,
                ],
                listText1: 'Customize your apparel',
                listText2: 'Express delivery available',
                listText3: 'Fully editable designs',
                listText4: '24/7 support',
                listText5: 'Live chat',
                listText6: 'Several payment methods available',
                metaTitle: 'Sports Gear Swag - Web Development | GeekyBones Case Studies',
                metaDescription: 'Custom sports apparels capture the brand identity! Sports Gear Swag offers craftsmanship and quality through an online website created by GeekyBones.',
            },
        },
        {
            "name": "mav3rik",
            "data": {
                projectTitle: `Get Ready to Change your World with Mav3rik`,
                projectImage: ASSETS.APPDETAILS.MavrikProject,
                projectDescription: `Mav3rik simplifies complexities and makes your business or work shine! It offers compelling UX and UI designs, bespoke mobile and web apps, innovative cloud-based solutions and seamless salesforce deployment, including automation, analytics and artificial intelligence.
`,
                technology: [
                    ASSETS.APPDETAILS.Wordpress,
                    ASSETS.APPDETAILS.PhpImg,
                    ASSETS.APPDETAILS.Gutenberg,
                    ASSETS.APPDETAILS.MySql,
                ],
                resultDescription: "The client had a vision of changing the world by impacting the organizations. Team GeekyBones helped the client to accomplish goals by creating a platform, solving the complexities of organizations.",
                resultSlideList: [
                ASSETS.APPDETAILS.MavrikHome,
                ASSETS.APPDETAILS.MavrikAbout,
                ASSETS.APPDETAILS.MavrikService,
                ASSETS.APPDETAILS.MavrikCase,
                ASSETS.APPDETAILS.MavrikContact,
                ],
                listText1: 'Seamless Salesforce deployment',
                listText2: 'Cloud-based solutions',
                listText3: 'Application development',
                listText4: 'User experience',
                metaTitle: 'Mav3rik Project - Web Development Case Studies | GeekyBones',
                metaDescription: 'Change the digital world with innovative solutions. The GeekyBones helped Mav3rik to solve the complexities and accomplish goals by creating a platform.',
               },
        },
        {
            "name": "auslankamatrimony",
            "data": {
                projectTitle: `Find your Soulmate in a Better Way!`,
                projectImage: ASSETS.APPDETAILS.AuslankmatrimonyProject,
                projectDescription: `The aim of https://www.auslankamatrimony.com.au/ website is to achieve maximum happy marriages by discovering and matching prospective Lanka brides and bridegrooms residing in Australia. 
`,
                technology: [
                    ASSETS.APPDETAILS.MySql,
                    ASSETS.APPDETAILS.PhpImg,
                    ASSETS.APPDETAILS.SymfonyImg,
                ],
                resultDescription: "The client wanted to build a website that caters to the matrimonial needs of Sri Lanka people in Australia. The aim was to bring smiles to people's faces by helping them discover the perfect life partner. Team GeekyBones successfully powered the client's vision by creating a safe and secure matrimonial website.",
                resultSlideList: [
                    ASSETS.APPDETAILS.AuslankamatrimonyWebsite1,
                    ASSETS.APPDETAILS.AuslankamatrimonyWebsite2,
                    ASSETS.APPDETAILS.AuslankamatrimonyWebsite3,
                    ASSETS.APPDETAILS.AuslankamatrimonyWebsite1,
                    ASSETS.APPDETAILS.AuslankamatrimonyWebsite2,
                    ASSETS.APPDETAILS.AuslankamatrimonyWebsite3,
                ],
                listText1: '100% confidential',
                listText2: 'Personalised service',
                listText3: 'Reasonable membership fees',
                listText4: 'Easy registration process',
                metaTitle: 'Auslankamatrimony - Web Development Case Studies | GeekyBones',
                metaDescription: "The website www.auslankamatrimony.com.au helps in finding the perfect match. GeekyBones powered the client's vision by creating a matrimonial website.",
            },
        },
        {
            "name": "freshy-helpline",
            "data": {
                projectTitle: `We Help you Make Easy Adjustments to Your New Life`,
                projectImage: ASSETS.APPDETAILS.FreshyProject,
                projectDescription: `Moving to a new place can be stressful where you need accommodation, jobs, vehicles, etc. Freshy Helpline in Melbourne supports newcomers, especially International students from various communities with basic needs.`,
                technology: [
                    ASSETS.APPDETAILS.NodeJsImg,
                    ASSETS.APPDETAILS.PhpImg,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.SymfonyImg,
                ],
                resultDescription: "The client wanted to build an online website and mobile app to help people from different communities in Melbourne through ads. The experts at GeekyBones mixed the creative ideas and right tech approach to fulfill client ideas successfully. ",
                resultSlideList: [
                    ASSETS.APPDETAILS.Freshy1,
                    ASSETS.APPDETAILS.Freshy2,
                    ASSETS.APPDETAILS.Freshy3,
                    ASSETS.APPDETAILS.Freshy4,
                ],
                listText1: 'Housing and Home Support',
                listText2: 'Find jobs',
                listText3: 'Buy and sell products through app',
                listText4: 'Post ads for your requirements',
                listText5: 'Filter your options according to your search, location, and price',
                metaTitle: 'Freshy Helpline - Case Studies | GeekyBones',
                metaDescription: 'Freshy Helpline supports newcomers with basic requirements. GeekyBones assisted the client with an online website and app to bring ideas into reality.',
            },
        },
        {
            "name": "candy-coin",
            "data": {
                projectTitle: `Satisfy your Sweet Tooth Cravings!`,
                projectImage: ASSETS.APPDETAILS.CandyCoinProject,
                projectDescription: `Candy Coin brings you deliciously tangy, eye-popping Sour Belts bags. Not only this, while enjoying your juicy flavors, you can also win a free Cryptocurrency. 
Explore the awesome flavors and products.`,
                technology: [
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.NextJs,
                    ASSETS.APPDETAILS.GraphQl,
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ReactImg,
                ],
                resultDescription: "The client wanted an online presence through the website so that customers can have plenty of sweetness. The platform offers versatility, bundled hosting and security, user-friendly software, and built-in features to facilitate transactions, creating an excellent shopping experience. With the right mix of technologies and innovative ideas, GeekyBones helped in fulfilling clients’ objectives.",
                resultSlideList: [
                    ASSETS.APPDETAILS.CandyCoin1,
                    ASSETS.APPDETAILS.CandyCoin2,
                    ASSETS.APPDETAILS.CandyCoin3,
                    ASSETS.APPDETAILS.CandyCoin1,
                    ASSETS.APPDETAILS.CandyCoin2,
                    ASSETS.APPDETAILS.CandyCoin3,
                ],
                listText1: 'Choose your favorite flavor',
                listText2: 'Add to cart',
                listText3: 'Choose the quantity and select the delivery date',
                listText4: 'Payment options - Credit Card, PayPal, and Check/PO',
                listText5: 'Redeem your code',
                listText6: 'Get exclusive offers',
                listText7: 'Join as a brand influencer',
                listText8: 'Join as wholesale distributors',
                metaTitle: 'Candy Coin - Web Development Case Studies | GeekyBones',
                metaDescription: 'Candy Coin offers juicy flavors for cravings. GeekyBones created an online platform fulfilling clients’ objectives, offering consumers with candies. ',
            },
        },
        {
            "name": "tadpole-rides",
            "data": {
                projectTitle: `Get on the Ride that Pays You Back!`,
                projectImage: ASSETS.APPDETAILS.TadPoleProject,
                projectDescription: `Tadpole applications and website caters to the riding requirements of the users while allowing them to earn residual income through referrals.`,
                technology: [
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.RestApi,
                ],
                resultDescription: "Our client wanted a website and apps that could be accessible to both drivers and riders. As we accepted our clients business as our own, we successfully delivered the project by accomplishing clients goals. From designing to development and then implementation, we ensured that our client was fully satisfied. ",
                resultSlideList: [ASSETS.APPDETAILS.TadpoleRidesWebsite, ASSETS.APPDETAILS.TadpoleRidesWebsite],
                listText1: 'Rides at fingertips',
                listText2: 'Rewarding and referral rides',
                listText3: 'Drive anytime',
                listText4: 'Easy-to-use interface',
                listText5: 'Safe and secure rides',
                listText6: 'Flexible payment options',
                metaTitle: 'Tadpole - Case Studies | GeekyBones',
                metaDescription: "Tadpole's website and applications cater to the riding requirements of the consumers. GeekyBones delivered the project by designing and implementing a website and apps.",
            },
        },
        {
            "name": "quality-minds",
            "data": {
                projectTitle: `Improve the Mental Well-Being and Live a Better Life!`,
                projectImage: ASSETS.APPDETAILS.QualityMindBanner,
                projectDescription: `Quality Mind is a proven program helping people to improve their lives by reducing mental pressure. A personal Mind Mentor provides support by creating a personal development plan to overcome anxiety and depression.`,
                technology: [
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.RestApi,
                ],
                resultDescription: "The client wanted to design a unique personal development program filled with balance, empowerment, and self-love, keeping the mind from self-destructive thoughts. Our team successfully fulfilled the client’s vision while developing robust app solutions. ",
                className: 'common-main-dev-mobile',
                resultSlideList: [
                    ASSETS.APPDETAILS.QualityApp1,
                    ASSETS.APPDETAILS.QualityApp2,
                    ASSETS.APPDETAILS.QualityApp3,
                    ASSETS.APPDETAILS.QualityApp4,
                ],
                listText1: 'Step by step information as your proceed',
                listText2: 'Real-time chat with Mind Mentor™',
                listText3: 'Written or audio journaling',
                listText4: 'Global leader board to track progress',
                listText5: 'Thought Shopping',
                listText6: 'Meditation',
                listText7: 'Bubble Popping',
                metaTitle: 'Quality Mind - Mobile App Development Case Studies | GeekyBones',
                metaDescription: "Quality Minds is a mobile app that helps in improving mental well-being for a better life. GeekyBones fulfilled the client's vision by developing robust application solutions.",
            },
        },
        {
            "name": "rx-tro",
            "data": {
                projectTitle: `Accomplish Tremendous Productivity Gains With Organised Appointments`,
                projectImage: ASSETS.APPDETAILS.RxTroBanner,
                projectDescription: `RxTro is a centralized industry platform for medical representatives in Australia. It helps organize daily visit logs, manage doctor details, and track other such records, which ultimately improves the productivity of medical representatives.`,
                technology: [
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.RestApi,
                ],
                resultDescription: "The client wanted to create a mobile application platform to help sales representatives in pharmaceutical industries improve their productivity with proper management. Team GeekyBones delivered the work surpassing the client’s expectations. ",
                className: 'common-main-dev-mobile',
                resultSlideList: [
                    ASSETS.APPDETAILS.RxTroMobile1,
                    ASSETS.APPDETAILS.RxTroMobile2,
                    ASSETS.APPDETAILS.RxTroMobile3,
                    ASSETS.APPDETAILS.RxTroMobile4,
                ],
                listText1: 'Manage Appointments',
                listText2: 'Stay updated with notifications',
                listText3: 'Organize daily logs',
                listText4: 'Get and complete Practice’s Request',
                metaTitle: 'RxTro - Mobile App Development Case Studies | GeekyBones',
                metaDescription: 'GeekyBones created the RxTro app to help medical representatives in Australia. It improves productivity by keeping track of daily visits, appointments, etc.',
            },
        },
        {
            "name": "freshy-helpline-mobile",
            "data": {
                projectTitle: `We Help you Make Easy Adjustments to Your New Life`,
                projectImage: ASSETS.APPDETAILS.FreshyBanner,
                projectDescription: `Moving to a new place can be stressful where you need accommodation, jobs, vehicles, etc. Freshy Helpline in Melbourne supports newcomers, especially International students from various communities with basic needs.`,
                technology: [
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.RestApi,
                ],
                resultDescription: "The client wanted to build an online website and mobile app to help people from different communities in Melbourne through ads. The experts at GeekyBones mixed the creative ideas and right tech approach to fulfill client ideas successfully.",
                className: 'common-main-dev-mobile',
                resultSlideList: [
                ASSETS.APPDETAILS.FreshySlider1,
                ASSETS.APPDETAILS. FreshySlider2,
                ASSETS.APPDETAILS. FreshySlider3,
                ASSETS.APPDETAILS. FreshySlider4,
                ],
                listText1: 'Housing and Home Support',
                listText2: 'Find jobs',
                listText3: 'Buy and sell products through app',
                listText4: 'Post ads for your requirements',
                listText5: 'Filter your options according to your search, location, and price',
                metaTitle: 'Freshy Helpline - Case Studies | GeekyBones',
                metaDescription: 'Freshy Helpline supports newcomers with basic requirements. GeekyBones assisted the client with an online website and app to bring ideas into reality.',
            },
        },
        {
            "name": "tadpole-rides-mobile",
            "data": {
                projectTitle: `Get on the Ride that Pays You Back!`,
                projectImage: ASSETS.APPDETAILS.TadpoleBanner,
                projectDescription: `Tadpole applications and website caters to the riding requirements of the users while allowing them to earn residual income through referrals.`,
                technology: [
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.RestApi,
                ],
                resultDescription: "Our client wanted a website and apps that could be accessible to both drivers and riders. As we accepted our clients business as our own, we successfully delivered the project by accomplishing clients goals. From designing to development and then implementation, we ensured that our client was fully satisfied. ",
                className: 'common-main-dev-mobile',
                resultSlideList: [
                    ASSETS.APPDETAILS.TadpoleMobile1,
                    ASSETS.APPDETAILS.TadpoleMobile2,
                    ASSETS.APPDETAILS.TadpoleMobile3,
                    ASSETS.APPDETAILS.TadpoleMobile4,
                ],
                listText1: 'Rides at fingertips',
                listText2: 'Rewarding and referral rides',
                listText3: 'Drive anytime',
                listText4: 'Easy-to-use interface',
                listText5: 'Safe and secure rides',
                listText6: 'Flexible payment options',
                metaTitle: 'Tadpole - Case Studies | GeekyBones',
                metaDescription: "Tadpole's website and applications cater to the riding requirements of the consumers. GeekyBones delivered the project by designing and implementing a website and apps.",
            },
        },
        {
            "name": "meeting-managment",
            "data": {
                projectTitle: `Manage your Meeting Effectively and Achieve the Desired Results`,
                projectImage: ASSETS.APPDETAILS.MeetingManagmentBanner,
                projectDescription: `Meetings are opportunities for discussions and collaborations. The 360 Meeting Management app helps in building great meeting habits. The platform allows you to attend the meetings effectively, be accountable for agenda, real-time note-taking, and much more.`,
                technology: [
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.RestApi,
                ],
                resultDescription: "The client wanted to create a meeting management platform, ensuring that meetings are focused and fruitful. Our team accomplished the client’s expectations by designing an easy and intuitive platform for better meetings. ",
                className: 'common-main-dev-mobile',
                resultSlideList: [
                    ASSETS.APPDETAILS.MeetingManagmentSlider,
                    ASSETS.APPDETAILS.MeetingManagmentSlider2,
                    ASSETS.APPDETAILS.MeetingManagmentSlider3,
                    ASSETS.APPDETAILS.MeetingManagmentSlider4,
                ],
                listText1: 'Create group meetings',
                listText2: 'Automatic notifications of the upcoming meeting',
                listText3: 'Agenda item lead',
                listText4: 'Agenda item priority',
                listText5: 'Add notes, and upload files in Meeting Agenda Item Creation',
                metaTitle: '360 Meeting Management - Case Studies | GeekyBones',
                metaDescription: 'The 360 Meeting Management app helps in managing the meetings. GeekyBones created this intuitive and easy platform for focused and fruitful discussions.',
            },
        },
        {
            "name": "vageto",
            "data": {
                projectTitle: `Food Market at your Convenience!`,
                projectImage: ASSETS.APPDETAILS.VegatoBanner,
                projectDescription: `Vegato App offers the luxury of discovering fresh and quality produce that is delivered at the doorstep. In addition, the platform allows customers to get a hassle-free online grocery on fingertips at affordable prices.`,
                technology: [
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.RestApi,
                ],
                resultDescription: "The client came with a mission of delivering fresh fruits and vegetables to customers at their doorstep so that they don’t have to move around in crowded local markets, bargaining for quality groceries. Our team developed a robust fresh food platform, helping the client to fulfill the mission successfully. ",
                className: 'common-main-dev-mobile',
                resultSlideList: [
                ASSETS.APPDETAILS.VagetoMobile1,
                ASSETS.APPDETAILS.VagetoMobile2,
                ASSETS.APPDETAILS.VagetoMobile3,
                ASSETS.APPDETAILS.VagetoMobile1,
                ASSETS.APPDETAILS.VagetoMobile2,
                ASSETS.APPDETAILS.VagetoMobile3,
                ],
                listText1: 'Superior and fresh items',
                listText2: 'Customer-friendly user interface',
                listText3: 'Low prices and amazing offers',
                listText4: 'Express delivery service-old',
                listText5: 'Search and order process',
                listText6: 'Secure payment options',
                listText7: 'On-time service-old',
                metaTitle: 'Vegato - Mobile App Development Case Studies | GeekyBones',
                metaDescription: 'Vegato App brings food market at your convenience. GeekyBones build a robust fresh food platform to deliver high-quality products at the doorstep.',
            },
        },
        {
            "name": "sideffect-alert",
            "data": {
                projectTitle: `Stay Updated with Lifesaving Alerts`,
                projectImage: ASSETS.APPDETAILS.SideffectBanner,
                projectDescription: `Sideeffect Alert app is based on the concept of harm minimization. The alert and awareness system offers the subscribers real-time data on dangerous chemicals and lethal synthetic drugs that are sold nearby.`,
                technology: [
                    ASSETS.APPDETAILS.PostreSql,
                    ASSETS.APPDETAILS.ExpressJs,
                    ASSETS.APPDETAILS.ReactImg,
                    ASSETS.APPDETAILS.RestApi,
                ],
                resultDescription: "The client had a vision of creating an app for the security of the people where they can keep themselves updated by receiving the notifications directly on their mobile phones. Our experts utilized the right tech support mixed with creative ideas delivering successful results.",
                className: 'common-main-dev-mobile',
                resultSlideList: [
                    ASSETS.APPDETAILS.SideffectMobile1,
                    ASSETS.APPDETAILS.SideffectMobile2,
                    ASSETS.APPDETAILS.SideffectMobile3,
                    ASSETS.APPDETAILS.SideffectMobile1,
                    ASSETS.APPDETAILS.SideffectMobile2,
                    ASSETS.APPDETAILS.SideffectMobile3,
                ],
                listText1: 'Drug alerts',
                listText2: 'Instant Notifications',
                listText3: 'Real-time data',
                metaTitle: 'Sideeffect - Mobile App Development Case Studies | GeekyBones ',
                metaDescription: 'GeekyBones utilised the right tech support and ideas to create a Sideeffect alert app based on the concept of harm minimization.',
            },
        },
    ];

    const row = data.filter((item) => item.name === name);

    if(row.length === 0){
        return <PageNotFound />;
    }
    return (
        <Layout headerCustomClass={'gb-header-main-wrapper-inner'} breadcrumbView={true} pageTitle={name} currentPage={name} lastRoute={'portfolio'} lastRouteUrl={'/portfolio/web-development'} metaTitle={row[0].data.metaTitle} metaDescription={row[0].data.metaDescription}>
            <Banner data={row[0].data} />
            <AppDetailTechnologySlider data={row[0].data}  />
            <Result data={row[0].data} />
            {name === 'all-in' ?
                <KeyFeatureAllIn/> : <KeyFeature data={row[0].data} />
            }
        </Layout>
    )
}
export default Detail;
